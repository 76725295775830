import { PAGE_TYPE_CART_INTERMEDIATE } from 'shared/consts'
import {
  CART_INTERMEDIATE_CSW_FILTER_CLICK,
  CARTINTERMEDIATE_COMBINED_CSWS_EXPERIMENT,
  CSW_CLICK_ON_ID_SELECTOR,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import { getExperimentPerTenantOnClient } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const { pageType } = store.getPublicRuntimeConfig().pageProperties || {}

  const { variant, isEnabled, name } = getExperimentPerTenantOnClient(
    CARTINTERMEDIATE_COMBINED_CSWS_EXPERIMENT
  )

  if (!isEnabled || pageType !== PAGE_TYPE_CART_INTERMEDIATE) {
    return
  }

  if (variant === EXPERIMENT_VARIATION.V1) {
    const cswContainer = document.querySelector(`[data-experiment~=${name}]`)
    const cartIntermediateTopFilter = '[data-qa-id~=form-cartIntermediateTop]'
    const topsellerCsw = document.querySelector('.e-TopsellerCsw')
    const cartIntermediateMiddleFilter =
      '[data-qa-id~=form-cartIntermediateMiddle]'
    const suggestionsCsw = document.querySelector('.e-SuggestionsCsw')
    const cartIntermediateBottomFilter =
      '[data-qa-id~=form-cartIntermediateBottom]'
    const ownBrandsCsw = document.querySelector('.e-OwnBrandsCsw')

    cswContainer?.addEventListener('click', event => {
      const element = <HTMLElement>event.target
      if (element.closest(cartIntermediateTopFilter)) {
        topsellerCsw?.classList.remove('u-hidden')
        suggestionsCsw?.classList.add('u-hidden')
        ownBrandsCsw?.classList.add('u-hidden')
        trackOptimizelyEvent(CSW_CLICK_ON_ID_SELECTOR)
      }

      if (element.closest(cartIntermediateMiddleFilter)) {
        suggestionsCsw?.classList.remove('u-hidden')
        ownBrandsCsw?.classList.add('u-hidden')
        topsellerCsw?.classList.add('u-hidden')
        trackOptimizelyEvent(CSW_CLICK_ON_ID_SELECTOR)
      }

      if (element.closest(cartIntermediateBottomFilter)) {
        ownBrandsCsw?.classList.remove('u-hidden')
        suggestionsCsw?.classList.add('u-hidden')
        topsellerCsw?.classList.add('u-hidden')
        trackOptimizelyEvent(CSW_CLICK_ON_ID_SELECTOR)
      }

      if (element.closest('[data-clientside-hook~="productDetailButton"]')) {
        trackOptimizelyEvent(CART_INTERMEDIATE_CSW_FILTER_CLICK)
      }
    })
  }
}
