import {
  AFTER_QUERY_SUGGEST_LAYER,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import {
  getExperimentPerTenantOnClient,
  useGetExperimentPerTenant,
} from 'shared/experiments/utils/tenantBasedExperimentsUtils'

export const useIsAfterQuerySuggestLayerExperimentNonDefault = () => {
  const { isEnabled, variant } = useGetExperimentPerTenant(
    AFTER_QUERY_SUGGEST_LAYER
  )

  return isEnabled && variant !== EXPERIMENT_VARIATION.DEFAULT
}

export const isAfterQuerySuggestLayerExperimentV2 = () => {
  const { isEnabled, variant } = getExperimentPerTenantOnClient(
    AFTER_QUERY_SUGGEST_LAYER
  )
  return isEnabled && variant === EXPERIMENT_VARIATION.V2
}
