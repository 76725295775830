import type { MetadataCustomData } from '@redteclab/api/clients/adserver-api'
import { type VolumeBasedPriceV1 } from '@redteclab/api/clients/bully'
import type { Context } from 'koa'

import type { OfferSellerV1, OfferV1 } from 'server/utils/pimServiceUtilsTypes'
import { type CartState } from 'types/redux'
import type { ResultsState, SearchHit } from 'types/search'
import { type PublicRuntimeConfig } from 'types/shopConfig'

export interface AdServerInjectionRequest {
  eventIds?: Array<number>
  type: string
  aid: string
  keywords: Array<string>
  wid: number
  widgetdetails: boolean
  csize: number
  start: number
}

type BrowserIdTargeting = string
type BrowserIdConversion = string
type UserId = string

export interface AdServerRequestUser {
  asbidc?: BrowserIdConversion
  asbidt?: BrowserIdTargeting
  asuid?: UserId
}

export interface AdServerReRankRequest {
  user: AdServerRequestUser
  keywords: Array<string>
  context: AdServerContext
  hitsWithVariants: HitWithVariants[]
}

export interface AdServerReRankResponse {
  hits: Array<{ objectID: string }>
  metadata: {
    [pzn: string]: AdServerTrackingUrls
  }
}

export type AdSeverReRankResponseType = {
  metadata: AdServerMetadata
  resultsState: ResultsState
  injectedProducts: AdServerProductItem[]
}

export interface HitWithVariants {
  id: string
  isOnFirstPage: boolean
  variants: Array<string>
}

export interface AdServerTrackingUrls {
  impressionUrl: string
  clickUrl: string
  events: Array<AdServerDecisionResponseEvent>
}

export interface AdServerMetadataContent {
  creativeId: number
  flightId: number
  campaignId: number
  trackingUrls: AdServerTrackingUrls
  position?: number
  customData?: {
    closeButton?: boolean
    topBannerDisplay?: string
    legalButton?: {
      label: string
      text: string
    }
  }
  productId?: string
}

export interface AdServerMetadata {
  [key: string]: AdServerMetadataContent
}

// The subset of AdServerMetadata type
// We store it in the cookie
export type AdServerMetadataCookieWithPzn = {
  [k: string]: AdServerMetadataCookie
}

export interface AdServerMetadataCookie
  extends Pick<
    AdServerMetadataContent,
    'creativeId' | 'flightId' | 'campaignId'
  > {
  trackingUrls: Pick<AdServerTrackingUrls, 'clickUrl'>
}

export type AdServerTrackingUrlsWithPzn = {
  [pzn: string]: AdServerTrackingUrls
}

export interface AdServerProductItem {
  product: SearchHitProduct
  position: number
}

export interface AdServerProductResponse {
  items: AdServerProductItem[]
  metadata: AdServerMetadata
}

export interface AdUserIdentity {
  browserIdConversion?: BrowserIdConversion
  browserIdTargeting?: BrowserIdTargeting
  userId?: UserId
}

export interface CommonParams {
  user: AdUserIdentity
  keywords: Array<string>
  ctx: Context
  adServerContext: AdServerContext
}

export enum PlacementTemplate {
  image = 'image',
}

export interface Placement {
  impressionUrl: string
  divName: string
  campaignId: number
  flightId: number
  creativeId: number
  width: number
  height: number
  clickUrl: string
  body: string
  type: string
  template?: string
  imageUrl?: string
  title?: string
  events: Array<AdServerDecisionResponseEvent>
  customData?: MetadataCustomData
}

export interface AdSlotDimensions {
  width: number
  height: number
}

export interface AdServerContext {
  pageType: string
  tenant: string
  language: string
  expa?: string
  brandName?: string
  categoryId?: string
  secondLevelCategories?: Array<string>
  manufacturerCode?: string
  searchTerm?: string
  partnerCode?: string
  adWord?: string
  campaignCodes?: string[]
  pzn?: string | string[]
  upid?: string | string[]
  rx?: boolean
  experiments?: string[]
  loggedIn?: boolean
}

export enum AdServerDecisionResponseContentType {
  raw = 'raw',
  html = 'html',
}

export enum AdServerPlacementTemplate {
  image = 'image',
}

interface AdServerDecisionResponseContentData {
  height: number
  width: number
  imageUrl?: string
  title?: string
  customData?: MetadataCustomData
}

export enum AdServerDecisionEventId {
  visible = 30,
}

export interface AdServerDecisionResponseEvent {
  id: AdServerDecisionEventId
  url: string
}

export interface AdServerDecisionResponseContent {
  type: string
  template?: string
  data: AdServerDecisionResponseContentData
  body: string
  bodyPrefix?: string
  bodySuffix?: string
}

export interface AdServerDecisionResponseContentRaw
  extends AdServerDecisionResponseContent {
  type: AdServerDecisionResponseContentType.raw
}

export interface AdServerDecisionResponseContentImageData
  extends AdServerDecisionResponseContentData {
  imageUrl: string
  title: string
}

export interface AdServerDecisionResponseContentImage
  extends AdServerDecisionResponseContent {
  type: AdServerDecisionResponseContentType.html
  template: PlacementTemplate.image
  data: AdServerDecisionResponseContentImageData
}

type AdServerDecisionResponseContentRecognised =
  | AdServerDecisionResponseContentRaw
  | AdServerDecisionResponseContentImage

export interface AdServerDecisionResponse {
  impressionUrl: string
  clickUrl: string
  creativeId: number
  flightId: number
  campaignId: number
  contents: Array<AdServerDecisionResponseContentRecognised>
  events: Array<AdServerDecisionResponseEvent>
  width?: number
  height?: number
}

export interface AdServerPlacementsResponse {
  user: string
  decisions: Record<string, AdServerDecisionResponse>
  data?: unknown
}

export type AdServerInjectedBoosterResponse = {
  metadata?: AdServerMetadata
}

export type GtmEventCallback = (value: unknown) => void

export enum GtmEventType {
  clickEvent = 'kev_click',
  impressionEvent = 'kev_impr',
  viewEvent = 'kev_view',
}

export interface GtmEvent {
  event: GtmEventType
  [key: string]: string | GtmEventCallback
}

interface SearchHitProductSeller
  extends Omit<OfferSellerV1, 'displayName' | 'id'> {
  name?: string
  id?: string
}

export interface SearchHitProductOffer
  extends Omit<OfferV1, 'offerId' | 'offerType' | 'seller'> {
  id?: string
  seller?: SearchHitProductSeller
  type?: string
  volume_based_prices: VolumeBasedPriceV1[]
}

export interface SearchHitProductVariant {
  id: string
  offers_count: number
  pricePerUnit: string
  retailPrice: number
  best_offer: SearchHitProductOffer
  nowProductGroup: string
  imageUrl: string
  text: string
}

export interface SearchHitProduct extends SearchHit {
  best_offer: SearchHitProductOffer
  productName: string
  offers_count: number
  id: string
  nowProductGroup: string
  variants: SearchHitProductVariant[]
  image: string
  descriptionShort: string
}

export enum AdServerSlotStatus {
  RENDERING = 'rendering',
  LOADING = 'loading',
  EMPTY = 'empty',
  FAILURE = 'fail',
  READY = 'ready',
}

export interface AdServerTargeting {
  context: AdServerContext
  keywords: Array<string>
}

export interface AdServerPlacementRequest {
  divName: string
  eventIds: Array<number>
  size: AdSlotDimensions
  tags?: Array<string>
}

export interface TargetingContextProps {
  cart?: CartState
  publicRuntimeConfig: PublicRuntimeConfig
  adServerSideContext?: Partial<AdServerContext>
}

export enum AdServerEventType {
  VISIBLE = 30,
  PLACED = 800,
}
