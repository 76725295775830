import {
  AFTER_QUERY_SUGGEST_LAYER,
  SEARCH_BOX_AUTO_SUGGEST_QUERY_SUGGESTION_CLICK,
  SEARCH_BOX_SUGGESTED_PRODUCT_CLICK,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

export default () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}

  const afterQuerySuggestLayerExperiment = getExperimentNamePerTenant(
    AFTER_QUERY_SUGGEST_LAYER,
    tenant,
    deviceClass
  )
  const { isEnabled } = getExperimentOnClient(afterQuerySuggestLayerExperiment)

  if (!isEnabled) {
    return
  }

  document.addEventListener('click', event => {
    const element = <HTMLElement>event.target

    if (
      element.closest('.o-SearchBox #h-SearchBox__input') ||
      element.closest('.o-SearchBox #query')
    ) {
      if (element.tagName === 'INPUT') {
        if (
          sessionStorage.getItem(afterQuerySuggestLayerExperiment) === 'true'
        ) {
          return
        }
        sessionStorage.setItem(afterQuerySuggestLayerExperiment, 'true')
        element.addEventListener(
          'input',
          () => {
            void activateExperiment(afterQuerySuggestLayerExperiment)
          },
          { once: true }
        )
      }
    } else if (
      element.closest(
        '[data-clientside-hook="searchBox_autoSuggest_querySuggestion"]'
      )
    ) {
      trackOptimizelyEvent(SEARCH_BOX_AUTO_SUGGEST_QUERY_SUGGESTION_CLICK)
    } else if (
      element.closest('[data-clientside-hook="SearchBoxListItemLink"]')
    ) {
      trackOptimizelyEvent(SEARCH_BOX_SUGGESTED_PRODUCT_CLICK)
    }
  })
}
