import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_LOGGED_IN,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_NOT_LOGGED_IN,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_LOGGED_IN,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_NOT_LOGGED_IN,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_CART_ICON,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_HAMBURGER,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO,
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_SEARCH_ICON,
  HEADER_SEARCH_BOX_CLICK,
  HEADER_SMALL_MENU_ITEM,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperiment,
} from 'shared/experiments/utils/experiments'
import { bindTrackingEvents } from 'shared/experiments/utils/tracking'
import store from 'shared/store'

export default () => {
  const { experiments } = store.getPublicRuntimeConfig().pageProperties || {}
  const { isEnabled, name } = getExperiment(HEADER_SMALL_MENU_ITEM, experiments)

  if (!isEnabled) {
    return
  }

  void activateExperiment(name)

  const headerSmallEventsAndApplyTracking = () =>
    bindTrackingEvents([
      {
        selector: '[data-clientside-hook="header-icon-logged-in"]',
        event: 'click',
        trackingEvent:
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_LOGGED_IN,
      },
      {
        selector: '[data-clientside-hook="header-icon-logged-out"]',
        event: 'click',
        trackingEvent:
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_ICON_NOT_LOGGED_IN,
      },
      {
        selector: '[data-clientside-hook="h-SearchBox__trigger__searchIcon"]',
        event: 'click',
        trackingEvent: EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_SEARCH_ICON,
      },
      {
        selector: '[data-clientside-hook="SmallHeader__cart-link"]',
        event: 'click',
        trackingEvent: EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_CART_ICON,
      },
      {
        selector: '.m-HeaderSmall__logo',
        event: 'click',
        trackingEvent: EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO,
      },
      {
        selector: '[data-qa-id="m-hamburger-menu"]',
        event: 'click',
        trackingEvent: EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_HAMBURGER,
      },
      {
        selector: '[data-qa-id="m-inside-hamburger-logged-in"]',
        event: 'click',
        trackingEvent:
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_LOGGED_IN,
      },
      {
        selector: '[data-qa-id="m-inside-hamburger-not-logged-in"]',
        event: 'click',
        trackingEvent:
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_ACCOUNT_INSIDE_BURGER_NOT_LOGGED_IN,
      },
      {
        selector: '.o-HeaderSmall__dummyInput',
        event: 'click',
        trackingEvent: HEADER_SEARCH_BOX_CLICK,
      },
    ])

  headerSmallEventsAndApplyTracking()
}
